import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The `}<em parentName="p">{`Grand Fortuna`}</em>{` was a merchant ship captained by `}<a parentName="p" {...{
        "href": "/Dalvarius%20Harstone",
        "title": "Dalvarius Harstone"
      }}>{`Dalvarius Harstone`}</a>{` that regularly sailed the `}<a parentName="p" {...{
        "href": "/Sejun%20Sea",
        "title": "Sejun Sea"
      }}>{`Sejun`}</a>{` and `}<a parentName="p" {...{
        "href": "/Haskan%20Sea",
        "title": "Haskan Sea"
      }}>{`Haskan`}</a>{` seas, trading goods between `}<a parentName="p" {...{
        "href": "/Aljiuedum",
        "title": "Aljiuedum"
      }}>{`Aljiuedum`}</a>{`, `}<a parentName="p" {...{
        "href": "/Tharsis",
        "title": "Tharsis"
      }}>{`Tharsis`}</a>{`, `}<a parentName="p" {...{
        "href": "/Esharn",
        "title": "Esharn"
      }}>{`Esharn`}</a>{`, and `}<a parentName="p" {...{
        "href": "/Volkharn",
        "title": "Volkharn"
      }}>{`Volkharn`}</a>{` until it sank in `}<a parentName="p" {...{
        "href": "/Boxe%20Bay",
        "title": "Boxe Bay"
      }}>{`Boxe Bay`}</a>{` near `}<a parentName="p" {...{
        "href": "/Gris",
        "title": "Gris"
      }}>{`Gris`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      